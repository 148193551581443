<template>
  <v-container class="">
    <v-row align="center" justify="center" class="mt-6">
      <h1>Specially for Mitya</h1>
    </v-row>
    <v-row class="mt-4 mb-2" justify="center">
      
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="methodName"
          dense
          outlined
          hide-details
          name="method-name"
          label="Назва методу"
        ></v-text-field>  
      </v-col>

      <v-col
        cols="12"
        md="7"
      >
        <v-text-field
          v-model="methodAtributes"
          dense
          outlined
          name="atributes-names"
          hide-details
          label="Атрибути"
          placeholder="name=value&..."
        ></v-text-field>  
      </v-col>
      
      <v-col
        cols="12"
        md="2"
        lg="1"
        class="text-center"
      >
        <v-btn :loading="loading" color="primary" @click="sendForm()"
        >
        
          Send
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="atributes">
      <v-subheader class="ml-md-12">Request Atributes: </v-subheader>
      <v-row  class="mt-3 mb-2" justify="center">
        <code>
          {{ atributes }}
        </code>
      </v-row>
    </template>
    
    <template v-if="responseData">
      <v-subheader class="ml-md-12">Response Data: </v-subheader>
      <v-row  class="mt-3 mb-2" justify="center">
        
        <pre style="overflow: auto;">
          {{ responseData }}
        </pre>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import axios from "axios"
export default {
  data: () => ({
      loading: false,
      methodName: '',
      methodAtributes: null,
      atributes: null,
      responseData: null
    }),
  methods:{
    sendForm(){
      this.loading = true
      this.responseData = null
      this.atributes = null
      axios({
          method: "post",
          url: this.$router.app.devUrlBillingAPI,
          data: {req_cmd: this.methodName, ...this.parseMethodAtribute()},
      })
        .then(response => {
          this.atributes = response.config.data
          console.log(response)
          this.responseData = response.data
        })
        .catch(err => {
            //console.log(err);
            this.$router.app.$sheet.show("Помилка Доступу в біллінг!", err);
        })
        .finally(() => (this.loading = false))
    },
    parseMethodAtribute(){
      
      if(!this.methodAtributes) return {}
      let atributes = this.methodAtributes.split('&')
      let atr = null
      let obj_atributes = {}
      atributes.forEach(element => {
        atr = element.split('=')
        obj_atributes[atr[0]] = atr[1]
      })
      return obj_atributes
    }
  }
}
</script>

